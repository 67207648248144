<script lang="ts">
  import { createEventDispatcher } from "svelte";
  import { copy } from "svelte-copy";

  export let textToCopy: string;
  export let copiedNotification: string = "Copied!";
  export let hintPosition: HintPosition = "right";
  // `full` size is needed in case of contained within a context menu,
  // otherwise the text will no take the entire row and clicks will no be handled
  export let size: "full" | "content" = "content";

  const dispatcher = createEventDispatcher();

  type HintPosition = "left" | "middle" | "right";

  let hintPositionMap = {
    middle: "items-center",
    left: "items-start",
    right: "items-end"
  };

  let wrapperDiv: HTMLButtonElement;

  function onCopy() {
    wrapperDiv.classList.add("copied");
    dispatcher("copied");
    setTimeout(() => {
      wrapperDiv.classList.remove("copied");
    }, 1000);
  }
</script>

<button
  use:copy={textToCopy}
  on:click|preventDefault|stopPropagation={() => false}
  class:w-full={size === "full"}
  class:text-left={size === "full"}
  class="outline-none focus:outline-none relative active:outline-none flex flex-col justify-center
    copy-to-clipboard {hintPositionMap[hintPosition]}"
  bind:this={wrapperDiv}
  on:svelte-copy={onCopy}>
  <div class:w-full={size === "full"} class="flex items-center justify-start copied-text">
    <slot />
  </div>

  <div class="absolute w-full copied-hint pointer-events-none" aria-hidden="true">
    {copiedNotification}
  </div>
</button>

<style>
  .copied-hint {
    transition: 0.3s ease-in-out;
    transition-property: opacity, transform;
    opacity: 0;
    transform: translateY(110%);
  }
  .copied-text {
    transition: 0.3s ease-in-out;
    transition-property: opacity, transform;
  }
  .copied .copied-hint {
    opacity: 1;
    transform: translateY(0);
  }
  .copied .copied-text {
    opacity: 0;
    transform: translateY(-110%);
  }
</style>
